// Desc: Banner component
import React from "react";
import bannerVideo from "./../assets/On online card payment_20240725_132755_0001.mp4";

const Banner = () => {
  return (
    <section className="lg:min-h-[20vh] md:min-h-[25vh] flex flex-col justify-center">
      <div className="text-center p-0 m-0 pb-5 flex flex-col gap-10 lg:flex-row lg:flex-wrap items-center justify-center">
        <video
          className="w-full m-0 p-0 bottom-0 lg:w-full h-auto md:max-w-[100vw] sm:max-w-[100vw] lg:max-w-[100vw]"
          src={bannerVideo}
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
    </section>
  );
};

export default Banner;
