import React from "react";
import { storage } from "./index";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { BiImageAdd } from "react-icons/bi";
import { NotifyWarning } from "../../toastify";

const UploadImage = ({ setAddProductData }) => {
  const [images, setImages] = React.useState([]);
  const [thumbnail, setThumbnail] = React.useState(null);
  const [thumbnailProgress, setThumbnailProgress] = React.useState(0);
  const [imageProgress, setImageProgress] = React.useState(0);
  const [imagesUploaded, setImagesUploaded] = React.useState(0);
  const [thumbnailUploaded, setThumbnailUploaded] = React.useState(0);

  // Handle image selection
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    setImageProgress(0); // Reset progress for new images
  };

  // Handle thumbnail selection
  const handleThumbnailChange = (e) => {
    if (e.target.files.length) {
      setThumbnail(e.target.files[0]);
    }
  };

  // Handle thumbnail upload
  const handleThumbnailUpload = () => {
    if (thumbnailUploaded > 0) {
      NotifyWarning("You can only upload thumbnail once.");
      return;
    } else {
      setThumbnailUploaded(1);
      if (thumbnail) {
        const thumbnailRef = ref(storage, `images/${thumbnail.name}`);
        const thumbnailTask = uploadBytesResumable(thumbnailRef, thumbnail);

        thumbnailTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setThumbnailProgress(progress);
          },
          (error) => {
            console.error("Thumbnail upload error:", error);
          },
          () => {
            getDownloadURL(thumbnailTask.snapshot.ref).then((downloadURL) => {
              setAddProductData((prevState) => ({
                ...prevState,
                selectedFile: [downloadURL, ...prevState.selectedFile], // Thumbnail as the first item
              }));
              setThumbnailProgress(100); // Mark as completed
            });
          }
        );
      }
    }
  };

  // Handle images upload
  const handleUpload = () => {
    if (imagesUploaded > 0) {
      NotifyWarning("You can only upload images once.");
      return;
    } else {
      setImagesUploaded(1);

      // Reset selectedFile to ensure only current uploads are stored
      setAddProductData((prevState) => ({
        ...prevState,
        selectedFile: [], // Reset selectedFile array
      }));

      images.forEach((image) => {
        const imageRef = ref(storage, `images/${image.name}`);
        const uploadTask = uploadBytesResumable(imageRef, image);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setImageProgress(progress);
          },
          (error) => {
            console.error("Image upload error:", error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setAddProductData((prevState) => ({
                ...prevState,
                selectedFile: [...prevState.selectedFile, downloadURL],
              }));
            });
          }
        );
      });
    }
  };
  return (
    <>
      {/* Thumbnail Upload Section */}
      <label>Upload Thumbnail</label>
      <div className="w-full mt-1">
        <input
          type="file"
          onChange={handleThumbnailChange}
          style={{ display: "none" }}
          id="thumbnail"
          alt="Upload Thumbnail"
          accept="image/*"
        />
        <label
          htmlFor="thumbnail"
          className="flex items-center justify-center px-4 py-2 border border-[#edd5da] rounded-md shadow-sm cursor-pointer"
        >
          <BiImageAdd className="text-xl" />
          &nbsp;
          {thumbnail ? (
            <span className="text-sm text-blue-500 ml-2">
              Thumbnail selected
            </span>
          ) : (
            "Upload Thumbnail"
          )}
          {thumbnail && (
            <button
              onClick={handleThumbnailUpload}
              className="ml-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              disabled={thumbnailProgress === 100}
            >
              {thumbnailProgress === 0
                ? "Upload Thumbnail"
                : thumbnailProgress === 100
                ? "Thumbnail Uploaded"
                : `Uploading... (${thumbnailProgress}%)`}
            </button>
          )}
        </label>
      </div>

      {/* Image Upload Section */}
      <label>Upload Images</label>
      <div className="w-full mt-1">
        <label
          htmlFor="image"
          className="flex items-center justify-center px-4 py-2 border border-[#edd5da] rounded-md shadow-sm cursor-pointer"
        >
          <BiImageAdd className="text-xl" />
          &nbsp;
          {images.length ? (
            <span className="text-sm text-blue-500 ml-2">
              {images.length} image(s) selected
            </span>
          ) : (
            "Upload Images"
          )}
          <input
            type="file"
            multiple
            onChange={handleChange}
            style={{ display: "none" }}
            name="image"
            id="image"
            alt="Upload Images"
            accept="image/*"
          />
          {images.length > 0 && (
            <button
              onClick={handleUpload}
              className="ml-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              disabled={imageProgress === 100}
            >
              {imageProgress === 0
                ? "Upload Images"
                : imageProgress === 100
                ? "Images Uploaded"
                : `Uploading... (${imageProgress}%)`}
            </button>
          )}
        </label>
      </div>
    </>
  );
};

export default UploadImage;
