import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginaUser,
  registeraUser,
} from "../../statemanagement/slice/AuthenticationSlice";
import { NotifyInfo } from "../../toastify";
export default function Auth({
  IsSignup,
  setIsSignup,
  text,
  closeModalDropDown,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authData, setAuthData] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    address: "",
    number: "",
  });
  function closeModal() {
    setIsOpen(false);
    window.innerWidth < 768 && closeModalDropDown();
  }
  function openModal() {
    setIsOpen(true);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (IsSignup) {
      if (checkbox === false) {
        return NotifyInfo("Please accept the terms and conditions");
      }
    }
    if (IsSignup) {
      if (authData.password !== authData.confirmPassword) {
        NotifyInfo("Password and Confirm Password must be same");
      }
      return dispatch(
        registeraUser({ authData, navigate, closeModal, closeModalDropDown })
      );
    }
    dispatch(
      loginaUser({ authData, navigate, closeModal, closeModalDropDown })
    );
  };
  const handleChange = (e) => {
    setAuthData({ ...authData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <p type="button" className="cursor-pointer" onClick={openModal}>
        {text}
      </p>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form
                    className="mb-0 md:space-y-5 space-y-2"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-black text-center"
                    >
                      {IsSignup
                        ? "Sign up for an account"
                        : "Log in to your account"}
                    </Dialog.Title>
                    {IsSignup && (
                      <>
                        <div className="w-full md:w-1/2 md:inline-block md:mr-1 text-black bg-white">
                          <label htmlFor="firstName">First Name</label>
                          <div className="mt-1">
                            <input
                              name="firstName"
                              type="text"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-[48%] md:inline-block text-black">
                          <label htmlFor="lastName">Last Name</label>
                          <div className="mt-1">
                            <input
                              onChange={handleChange}
                              name="lastName"
                              type="text"
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      <label htmlFor="email" className="text-black">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={handleChange}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                        />
                      </div>
                    </div>
                    <div
                      className={
                        IsSignup
                          ? "w-full md:w-1/2 md:inline-block md:mr-1"
                          : "w-full"
                      }
                    >
                      <label htmlFor="password" className="text-black">
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={handleChange}
                          name="password"
                          type="password"
                          onClick={(e) => {
                            e.target.type = "text";
                          }}
                          onBlur={(e) => {
                            e.target.type = "password";
                          }}
                        />
                      </div>
                    </div>
                    {IsSignup && (
                      <>
                        <div className="w-full md:w-[48%] md:inline-block text-black">
                          <label htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={handleChange}
                              name="confirmPassword"
                              type="password"
                              onClick={(e) => {
                                e.target.type = "text";
                              }}
                              onBlur={(e) => {
                                e.target.type = "password";
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-full md:w-1/2 md:inline-block md:mr-1 text-black">
                          <label htmlFor="address">Address</label>
                          <div className="mt-1">
                            <input
                              onChange={handleChange}
                              name="address"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="w-full md:w-[48%] md:inline-block">
                          <label htmlFor="number">Contact Number</label>
                          <div className="mt-1">
                            <input
                              onChange={handleChange}
                              name="number"
                              type="tel"
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {IsSignup && (
                      <div className="flex items-center">
                        <input
                          id="terms-and-privacy"
                          name="terms-and-privacy"
                          type="checkbox"
                          className="h-4 w-4 text-black focus:ring-blue-500 border-gray-300 rounded"
                          checked={checkbox}
                          onChange={() => setCheckbox(!checkbox)}
                        />
                        <label
                          htmlFor="terms-and-privacy"
                          className="ml-2 block text-sm text-blue-900"
                        >
                          I agree to the
                          <a href="/" className="text-black hover:text-black">
                            {" "}
                            Terms{" "}
                          </a>
                          and
                          <a href="/" className="text-black hover:text-black">
                            {" "}
                            Privacy Policy{" "}
                          </a>
                          .
                        </label>
                      </div>
                    )}
                    <div>
                      <button
                        className="w-1/2 inline-block mr-1 text-black"
                        type="button"
                        onClick={() => {
                          setIsSignup(!IsSignup);
                        }}
                      >
                        {IsSignup ? "Sign in instead" : "Create Account"}
                      </button>
                      <button
                        type="submit"
                        className="w-[48%] inline-block justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-[#1f3f9c] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1f3f9c]"
                      >
                        {IsSignup ? "Sign Up" : "Log In"}
                      </button>
                    </div>
                    <p
                      className="text-center text-sm text-black
                                        first-letter:capitalize"
                    >
                      click on the overlay to close the popup window
                    </p>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
