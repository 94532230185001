import React, { useState } from "react";
import { NotifyInfo } from "../../../toastify";

export default function Categorys({
  category: initialCategory,
  setCategory,
  AddProductData,
}) {
  const ENTER = "Enter";
  const COMMA = ",";
  const SPACE = " ";
  const BACKSPACE = "Backspace";

  const [value, setValue] = useState("");

  // Ensure that category state is derived from props
  const [category, setCategoryState] = useState(initialCategory || []);

  const handleKeyUp = (e) => {
    const key = e.key;
    if (key === COMMA || key === SPACE || key === ENTER) {
      addCategory();
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (key === BACKSPACE && !value) {
      editCategory();
    }
  };

  const addCategory = () => {
    let addCat = value.trim().replace(/,/g, "");
    if (!addCat) return;
    if (category.find((t) => t.toLowerCase() === addCat.toLowerCase())) return;
    const updatedCategory = [...category, addCat];
    setCategoryState(updatedCategory);

    // Update only the 'category' property while preserving the rest of AddProductData
    setCategory((prev) => ({
      ...prev, // Spread the existing state
      category: updatedCategory, // Update only the category
    }));

    setValue("");
  };

  const resetCategory = () => {
    const defaultCategories = ["Men", "Women", "Kids"];
    setValue("");
    setCategoryState(defaultCategories);

    // Update only the 'category' property while preserving the rest of AddProductData
    setCategory((prev) => ({
      ...prev, // Spread the existing state
      category: defaultCategories, // Update only the category
    }));
  };

  const editCategory = () => {
    if (category.length > 1) {
      const lastCategory = category.pop();
      setValue(lastCategory || "");
      setCategoryState([...category]);

      // Update only the 'category' property while preserving the rest of AddProductData
      setCategory((prev) => ({
        ...prev, // Spread the existing state
        category: [...category], // Update only the category
      }));
    } else {
      NotifyInfo("You must have at least one category");
    }
  };

  return (
    <div
      className="
        flex flex-wrap
        items-center
        justify-center
        w-full
      "
    >
      {category.map((cat, index) => (
        <div
          key={index}
          className="
            flex items-center
            bg-black
            rounded-full
            px-[0.6rem] py-1
            mb-0 mr-2
            text-sm
            text-white
            font-normal
          "
        >
          {cat}
          <button
            type="button"
            className="
              ml-[0.3rem]
              flex-shrink-0
              flex
              items-center
              justify-center
              h-4 w-4
              rounded-full
              bg-white
              text-black
              leading-none
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-offset-gray-100
              focus:ring-[#1f3f9c]
            "
            onClick={() => {
              if (category.length > 1) {
                const updatedCategory = category.filter((t) => t !== cat);
                setCategoryState(updatedCategory);

                // Update only the 'category' property while preserving the rest of AddProductData
                setCategory((prev) => ({
                  ...prev, // Spread the existing state
                  category: updatedCategory, // Update only the category
                }));
              } else {
                NotifyInfo("You must have at least one category");
              }
            }}
          >
            <span className="sr-only">Remove Collection</span>
            <svg
              className="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M1 1l6 6M1 7l6-6"
              />
            </svg>
          </button>
        </div>
      ))}
      <button
        type="button"
        className=" bg-black rounded-full px-3 py-1 mb-0 mr-2 text-sm text-white font-normal"
        onClick={resetCategory}
      >
        Reset
      </button>
      <input
        className="mt-2"
        type="text"
        placeholder="Press enter, space or comma to add collection"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
