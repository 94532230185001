import * as api from "../../api/ShoeApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotifyError, NotifySuccess, NotifyWarning } from "../../../toastify";

// Slice for shoe
export const initialState = {
  error: null,
  shoeData: [],
  loungingData: [],
  everydayData: [],
  runningData: [],
  singleShoeData: "",
  topShoeData: [],
  loading: false,
};

// getAllShoe: fetch all shoes from the database
export const getAllShoe = createAsyncThunk(
  "Shoe/getAllShoe",
  async (
    { page, limit, sort, brand, size, category, price },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { data, runnning, lounging, everyday },
      } = await api.GetAllShoeAPI({
        page,
        limit,
        sort,
        brand,
        size,
        category,
        price,
      });
      return { data, runnning, lounging, everyday };
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        NotifyWarning(
          error?.response?.data?.message || "Error please  reload page"
        );
        return rejectWithValue(
          error?.response?.data?.message || "Error please  reload page"
        );
      } else {
        NotifyError(error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);

// getTopShoe: fetch top shoes from the database based on the number of products sold
export const getTopShoe = createAsyncThunk(
  "Shoe/getTopShoe",
  async (rejectWithValue) => {
    try {
      const {
        data: { data },
      } = await api.GetTopShoeAPI();
      return data;
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        NotifyWarning(
          error?.response?.data?.message || "Error please  reload page"
        );
        return rejectWithValue(
          error?.response?.data?.message || "Error please  reload page"
        );
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// getShoeById: fetch a single shoe from the database based on the shoe id
export const getShoeById = createAsyncThunk(
  "Shoe/getShoeById",
  async (shoeId) => {
    return shoeId;
  }
);

// getShoeByIdOnPageLoad: fetch a single shoe from the database based on the shoe id on page load
export const getShoeByIdOnPageLoad = createAsyncThunk(
  "Shoe/getShoeByIdOnPageLoad",
  async (shoeId, { rejectWithValue }) => {
    try {
      const {
        data: { data, message },
      } = await api.GetShoeByIdAPI(shoeId);
      NotifySuccess(message);
      return data;
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        NotifyWarning(
          error?.response?.data?.message || "Error please  reload page"
        );
        return rejectWithValue(
          error?.response?.data?.message || "Error please  reload page"
        );
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// createShoe: add a new shoe to the database
export const createShoe = createAsyncThunk(
  "Shoe/createShoe",
  async ({ closeModal, AddProductData }, { rejectWithValue }) => {
    try {
      const {
        data: { message },
      } = await api.CreateShoeAPI(AddProductData);
      closeModal();
      NotifySuccess(message);
      return;
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        NotifyWarning(
          error?.response?.data?.message || "Error please  reload page"
        );
        return rejectWithValue(
          error?.response?.data?.message || "Error please  reload page"
        );
      } else {
        NotifyError(error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);

// deleteShoe: delete a shoe from the database
export const deleteShoe = createAsyncThunk(
  "Shoe/deleteShoe",
  async ({ shoeId, product }, { rejectWithValue }) => {
    try {
      const response = await api.deleteShoeAPI(shoeId);
      //   console.log("API Response:", response);
      NotifySuccess(response.data.message);
      return shoeId;
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        NotifyWarning(
          error?.response?.data?.message || "Error please reload page"
        );
        return rejectWithValue(
          error?.response?.data?.message || "Error please reload page"
        );
      } else {
        NotifyError(error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);

// updateShoe: update a shoe in the database
export const updateShoe = createAsyncThunk(
  "Shoe/updateShoe",
  async ({ closeModal, shoeId, updateProductData }, { rejectWithValue }) => {
    try {
      const {
        data: { message },
      } = await api.updateShoeAPI(shoeId, updateProductData);
      closeModal();
      NotifySuccess(message);
      return;
    } catch (error) {
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        NotifyWarning(
          error?.response?.data?.message || "Error please  reload page"
        );
        return rejectWithValue(
          error?.response?.data?.message || "Error please  reload page"
        );
      } else {
        NotifyError(error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);
