import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getShoeById,
  getShoeByIdOnPageLoad,
} from "../statemanagement/slice/ShoeSlice/index";
// import { decodeToken } from "react-jwt";
import {
  addCarts,
  CartisOpen,
  checkoutAct,
} from "../statemanagement/slice/cartSlice";
import {
  LoadingBtn,
  LoadingCircle,
  LoadingSinglePage,
  NotifyWarning,
} from "../toastify";
import { OrderSizeOption } from "../components/Model/productFunctions/sizeOption";
import { FaFire, FaHeart, FaRegHeart, FaShoppingCart } from "react-icons/fa";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import { addWishList, deleteWishList } from "../statemanagement/slice/WishList";

const ProductDetails = () => {
  const { id } = useParams();
  const token = localStorage.getItem("authenticate");
  const wishListIDs = useSelector((state) => state.wishList.wishListIDs);
  // const decodeData = decodeToken(token);
  const dispatch = useDispatch();

  const [selectedSize, setSelectedSize] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [animationClass, setAnimationClass] = useState("");

  const { status } = useSelector((state) => state.cart);
  // const { cartData, status } = useSelector((state) => state.cart);
  const { singleShoeData, loading } = useSelector((state) => state.shoeDetails);

  const [quantityUserAdd, setQuantityUserAdd] = useState(1);

  const addToCart = async ({ product, shoeId }) => {
    if (!selectedSize) {
      NotifyWarning("Please select a size before adding to the cart.");
      return;
    }

    await dispatch(
      addCarts({
        product: { ...product },
        selectedSize,
        shoeId,
        quantityUserAdd,
      })
    );
    await dispatch(CartisOpen(true));
  };

  useEffect(() => {
    dispatch(getShoeById(id));
    dispatch(getShoeByIdOnPageLoad(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (singleShoeData?.selectedFile?.length > 1) {
      setCurrentImage(singleShoeData.selectedFile[1]);
    }
  }, [singleShoeData]);

  const handleImageClick = (image) => {
    setAnimationClass("fade-out"); // Trigger fade-out animation
    setTimeout(() => {
      setCurrentImage(image);
      setAnimationClass("fade-in"); // Trigger fade-in animation
    }, 300); // Match this time with fade-out duration
  };

  const CheckoutBtn = async (data) => {
    if (!selectedSize) {
      NotifyWarning("Please select a size before proceeding to checkout.");
      return;
    }

    await dispatch(
      addCarts({
        product: { ...data, selectedSize },
        shoeId: data?._id,
        notification: false,
        quantityUserAdd,
      })
    );
    await dispatch(checkoutAct(data?.price));
  };

  const updateQuantity = (type) => {
    if (type === "increase") {
      if (quantityUserAdd < singleShoeData?.quantity) {
        setQuantityUserAdd((prevQuantity) => prevQuantity + 1);
      }
    } else if (type === "decrease") {
      if (quantityUserAdd > 1) {
        setQuantityUserAdd((prevQuantity) => prevQuantity - 1);
      }
    }
  };

  let sold = 0;

  // Get window URL
  const currentURL = window.location.href;

  // Check if the URL is the same as the one stored in localStorage
  const storedURL = localStorage.getItem("windowURL");

  if (currentURL === storedURL) {
    // console.log("Same Page");
    // Retrieve the stored sold value
    sold = localStorage.getItem("randomSold");
    // console.log(sold);
  } else {
    // Generate a random number for sold in the last 24 hours
    const randomSold = Math.floor(Math.random() * 6) + 2;

    // Store the new randomSold value and update the URL in localStorage
    localStorage.setItem("randomSold", randomSold);
    localStorage.setItem("windowURL", currentURL);

    // console.log("Not Same Page", randomSold);
    sold = randomSold;
  }

  if (loading || !singleShoeData) return <LoadingSinglePage />;

  return (
    <div className="bg-white">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap -mx-4">
          {/* Product Images */}
          <div className="w-full md:w-1/2 px-4 mb-8">
            {/* Display the first image as the main image */}
            <img
              src={currentImage}
              alt="Product"
              className={`w-full h-auto rounded-lg shadow-md mb-4 ${animationClass}`}
              id="mainImage"
              onAnimationEnd={() => {
                if (animationClass === "fade-in") {
                  setAnimationClass("");
                }
              }}
            />
            {/* Thumbnails */}
            <div className="flex gap-4 py-4 overflow-auto">
              {singleShoeData.selectedFile?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-1/6 size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div>
          </div>
          {/* Product Details */}
          <div className="w-full md:w-1/2 px-4">
            <h2 className="text-3xl font-bold mb-2">{singleShoeData?.title}</h2>
            <p className="text-gray-600 mb-4 hidden">
              ID: {singleShoeData?._id}
            </p>
            <div className="text-red-600 p-1 pt-0 pl-0">
              <FaFire className="inline -mt-2" size={20} />
              {singleShoeData?.sold <= 1 ? sold : singleShoeData?.sold} pieces
              sold in last 24 hours
            </div>
            <div className="mb-4">
              <span className="text-2xl font-bold mr-2">
                Rs. {singleShoeData?.discountedPrice}
              </span>
              <span className="text-gray-500 line-through">
                Rs. {singleShoeData?.price}
              </span>
            </div>
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Sizes:</h3>
              <div className="flex space-x-2 justify-left">
                <OrderSizeOption
                  sizes={singleShoeData?.size}
                  selectedSize={selectedSize}
                  setSelectedSize={setSelectedSize}
                />
              </div>
            </div>

            <div className="flex justify-around items-center w-full lg:w-1/2 md:w-1/2 px-2 py-1 mb-1 rounded-lg border border-black bg-white">
              {/* Quantity Control */}
              <div className="flex items-center text-black">
                <p className="text-sm font-bold mr-2">Quantity:</p>
                <IoIosAddCircle
                  title="Add"
                  className="hover:text-black hover:scale-110 cursor-pointer"
                  onClick={() => updateQuantity("increase")}
                />
                <p className="mx-2">
                  {status === "increment" ? (
                    <LoadingBtn color="black" width={4} />
                  ) : (
                    quantityUserAdd
                  )}
                </p>
                <AiFillMinusCircle
                  title="Minus"
                  className="hover:text-black hover:scale-110 cursor-pointer"
                  onClick={() => updateQuantity("decrease")}
                />
              </div>

              {/* Wishlist Control */}
              <div className="flex items-center text-black">
                {!token ? (
                  <FaRegHeart
                    className="text-2xl transition duration-500 cursor-pointer"
                    title="Add to WishList"
                    onClick={() =>
                      NotifyWarning(
                        "You must login or register to add items to your wishlist"
                      )
                    }
                  />
                ) : wishListIDs?.includes(singleShoeData?._id) ? (
                  loading ? (
                    <LoadingCircle />
                  ) : (
                    <FaHeart
                      className="text-2xl text-red-600 transition duration-500 cursor-pointer"
                      title="WishListed"
                      onClick={() =>
                        dispatch(deleteWishList(singleShoeData?._id))
                      }
                    />
                  )
                ) : loading ? (
                  <LoadingCircle />
                ) : (
                  <FaRegHeart
                    className="text-2xl transition duration-500 cursor-pointer"
                    title="Add to WishList"
                    onClick={() =>
                      dispatch(
                        addWishList({
                          shoeId: singleShoeData?._id,
                          product: singleShoeData,
                        })
                      )
                    }
                  />
                )}
              </div>
            </div>
            <div className="flex space-x-4 mb-1">
              <button
                className="bg-black flex gap-2 items-center justify-center text-white px-6 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-full"
                onClick={() =>
                  addToCart({ product: singleShoeData, shoeId: id })
                }
              >
                <FaShoppingCart />
                Add to Cart
              </button>
            </div>
            <div className="flex space-x-4 mb-6">
              <button
                className="bg-white border border-solid border-black flex gap-2 items-center justify-center text-gray-800 px-6 py-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 w-full"
                onClick={() => CheckoutBtn(singleShoeData)}
              >
                <MdOutlineShoppingCartCheckout />
                Checkout Now
              </button>
            </div>
            <p className="text-gray-700 mb-6">{singleShoeData?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
