import React from "react";
import { NotifyInfo } from "../../../toastify";

export default function SizeOption({ sizes, setSizes, AddProductData }) {
    const resetSizes = () => {
        setSizes({
            ...AddProductData,
            size: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
        });
    };

    return (
        <div className="
            flex flex-wrap
            items-center
            justify-center
            w-full
        ">
            {sizes?.map((size, index) => (
                <div key={index} className="
                    flex items-center
                    bg-black
                    rounded-full
                    px-[0.6rem] py-1
                    mb-2 mr-2
                    text-sm
                    text-white
                    font-normal
                ">
                    {size}
                    <button
                        type="button"
                        className="
                            ml-[0.3rem]
                            flex-shrink-0
                            flex
                            items-center
                            justify-center
                            h-4 w-4
                            rounded-full
                            bg-white
                            text-black
                            leading-none
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-offset-gray-100
                            focus:ring-[#1f3f9c]
                        "
                        onClick={() => {
                            const newSizes = sizes.filter((s) => s !== size);
                            setSizes({
                                ...AddProductData,
                                size: newSizes,
                            });
                            if (newSizes.length === 0) {
                                NotifyInfo("You must have at least one size option");
                                setSizes({
                                    ...AddProductData,
                                    size: [size],
                                });
                            }
                        }}
                    >
                        <span className="sr-only">Remove size</span>
                        <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1l6 6M1 7l6-6"
                            />
                        </svg>
                    </button>
                </div>
            ))}
            <button
                type="button"
                className="bg-black rounded-full px-3 py-1 mb-2 mr-2 text-sm
                    text-white font-normal"
                onClick={resetSizes}
            >
                Reset
            </button>
        </div>
    );
}

export const OrderSizeOption = ({ sizes, selectedSize, setSelectedSize }) => {
  return (
    <div className="flex flex-wrap items-left justify-left w-full">
      {sizes?.map((size, index) => (
        <button
          key={index}
          className={`flex items-center bg-black rounded-full px-[0.6rem] py-1 mb-2 mr-2 text-sm text-white font-normal ${
            selectedSize === size ? "bg-blue-500" : ""
          }`}
          onClick={() => setSelectedSize(size)}
        >
          {size}
        </button>
      ))}
    </div>
  );
};
