import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TbBrandGoogle } from "react-icons/tb";

const Checkout = ({ isOpen, setIsOpen, cartData }) => {
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-full p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="py-16 px-4 md:px-6 2xl:px-0 flex justify-center items-center 2xl:mx-auto 2xl:container">
                    <div className="flex flex-col justify-start items-start w-full space-y-9">
                      <div className="flex justify-start flex-col items-start space-y-2">
                        <p className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
                          Checkout
                        </p>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {cartData.map((item) => (
                          <div
                            key={item._id}
                            className="flex flex-col justify-center items-center bg-gray-100 py-7 px-10"
                          >
                            <div className="flex flex-col justify-start items-start w-full space-y-4">
                              <p className="text-xl md:text-2xl leading-normal text-gray-800">
                                {item.title}
                              </p>
                              <p className="text-base font-semibold leading-none text-gray-600">
                                {item.quantityUserAdd} x {item.price} = Rs.{" "}
                                {item.price * item.quantityUserAdd}
                              </p>
                            </div>
                            <div className="mt-6 w-52">
                              <img
                                src={item.selectedFile[0]}
                                alt={item.title}
                              />
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Payment and Billing Information */}
                      <div className="p-8 bg-gray-100 grid grid-cols-1 lg:grid-cols-2 gap-8">
                        <div className="lg:col-span-1">
                          <label className="text-base leading-4 text-gray-800">
                            Address Information
                          </label>
                          <div className="mt-2 flex-col space-y-6">
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                              type="text"
                              placeholder="Street Address"
                            />
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                              type="text"
                              placeholder="City"
                            />
                            <div className="flex space-x-4">
                              <input
                                className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                                type="text"
                                placeholder="State"
                              />
                              <input
                                className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                                type="text"
                                placeholder="ZIP"
                              />
                            </div>
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                              type="text"
                              placeholder="Country"
                            />
                          </div>

                          <label className="mt-8 text-base leading-4 text-gray-800">
                            Phone Number
                          </label>
                          <div className="mt-2">
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                              type="tel"
                              placeholder="Phone Number"
                            />
                          </div>

                          <label className="mt-8 text-base leading-4 text-gray-800">
                            Email Address
                          </label>
                          <div className="mt-2">
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                              type="email"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="lg:col-span-1">
                          <div className="border border-solid border-black p-2 rounded">
                            <div className="text-black text-center mb-2">Get Free Gift</div>
                            <button className="border border-transparent hover:border-gray-300 bg-gray-900 hover:bg-white text-white hover:text-gray-900 flex flex-row justify-center items-center space-x-2 py-4 rounded w-full">
                              <div>
                                <svg
                                  className="fill-current"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.9099 4.27692C9.6499 4.27692 9.1174 4.87817 8.2399 4.87817C7.34021 4.87817 6.65396 4.28129 5.56208 4.28129C4.49333 4.28129 3.35365 4.93379 2.6299 6.04535C1.61365 7.61285 1.78615 10.565 3.43208 13.08C4.02083 13.9804 4.80708 14.99 5.83833 15.001H5.85708C6.75333 15.001 7.01958 14.4141 8.25302 14.4072H8.27177C9.48677 14.4072 9.73052 14.9975 10.623 14.9975H10.6418C11.673 14.9866 12.5015 13.8679 13.0902 12.971C13.514 12.326 13.6715 12.0022 13.9965 11.2725C11.6155 10.3688 11.233 6.99348 13.5877 5.69942C12.869 4.79942 11.859 4.27817 10.9068 4.27817L10.9099 4.27692Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M10.6338 1C9.88379 1.05094 9.00879 1.52844 8.49629 2.15188C8.03129 2.71688 7.64879 3.555 7.79879 4.36781H7.85879C8.65754 4.36781 9.47504 3.88688 9.95254 3.27063C10.4125 2.68406 10.7613 1.85281 10.6338 1V1Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                              <div>
                                <p className="text-base leading-4">Pay</p>
                              </div>
                            </button>
                            <br className="-bottom-2 -top-2"/>
                            <button className="border border-transparent hover:border-gray-300 bg-gray-900 hover:bg-white text-white hover:text-gray-900 flex flex-row justify-center items-center space-x-2 py-4 rounded w-full">
                              <div>
                                <TbBrandGoogle />
                              </div>
                              <div>
                                <p className="text-base leading-4">Pay</p>
                              </div>
                            </button>
                          </div>
                          <br />
                          <button className="border border-transparent hover:border-gray-300 bg-gray-900 hover:bg-white text-white hover:text-gray-900 flex flex-row justify-center items-center space-x-2 py-4 rounded w-full">
                            <div>
                              <p className="text-base leading-4">
                                Cash On Delivery
                              </p>
                            </div>
                          </button>
                          {/* <div className="flex flex-row justify-center items-center mt-6">
                            <hr className="border w-full" />
                            <p className="flex flex-shrink-0 px-4 text-base leading-4 text-gray-600">
                              or pay with card
                            </p>
                            <hr className="border w-full" />
                          </div>

                          <div className="mt-8">
                            <input
                              className="border border-gray-300 p-4 rounded w-full text-base leading-4 placeholder-gray-600 text-gray-600"
                              type="email"
                              placeholder="Email"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Checkout;
